/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Flex, Image, Box, useMediaQuery, AspectRatio } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Button from '../button';
import PageArrow from '../svgIcon/pageArrow';
import gtagEvent from '../../utils/gtag';
import OfficePhoto0 from '../../../static/new_images/officePhotos/0.jpg';
import OfficePhoto1 from '../../../static/new_images/officePhotos/1.jpg';
import OfficePhoto2 from '../../../static/new_images/officePhotos/2.jpg';
import OfficePhoto3 from '../../../static/new_images/officePhotos/3.jpg';
import OfficePhoto4 from '../../../static/new_images/officePhotos/4.jpg';
import OfficePhoto5 from '../../../static/new_images/officePhotos/5.jpg';

const OfficePhotos = [
  OfficePhoto0,
  OfficePhoto1,
  OfficePhoto2,
  OfficePhoto3,
  OfficePhoto4,
  OfficePhoto5,
];

const FeatureSwiper = () => {
  const [swiper, setSwiper] = useState(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [isDESKTOP] = useMediaQuery('(min-width: 1200px)', {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  });

  const prev = () => {
    gtagEvent('bloctoweb_click_arrow', {
      location: 'feature_article_block',
    });
    swiper.slideTo(0);
  };
  const nexto = () => {
    gtagEvent('bloctoweb_click_arrow', {
      location: 'feature_article_block',
    });
    swiper.slideTo(3);
  };

  const swiperProps = isDESKTOP
    ? { slidesPerView: 3 }
    : {
        width: '276',
        breakpoints: {
          768: {
            width: 363,
          },
        },
      };

  return (
    <Box
      px={{ base: '24px', md: '64px', lg: '120px' }}
      pt={{ base: '40px', md: '80px', lg: '120px' }}
      pb={{ base: '40px', md: '80px', lg: '120px' }}
      borderRadius={{ base: '30px', lg: '60px' }}
    >
      <Box w="100%" maxW="1200px" m="0 auto">
        <Box w="100%">
          <Swiper
            spaceBetween={12}
            style={{
              paddingBottom: '24px',
            }}
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 24,
              },
              // when window width is >= 480px
              768: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
            }}
            onSwiper={(s) => {
              setSwiper(s);
            }}
            onSlideChange={(e) => {
              setSwiperIndex(e.realIndex);
            }}
            {...swiperProps}
          >
            {OfficePhotos.map((image, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={i}>
                <AspectRatio ratio={4 / 3}>
                  <Image src={image} borderRadius={{ base: '30px', md: '44px' }} />
                </AspectRatio>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Flex
          w="100%"
          justify="flex-end"
          p={{ base: '0 0 40px 0', sm: '0 0 64px 64px', lg: '0' }}
          display={{ base: 'none', lg: 'flex' }}
          maxW={{ md: '1200px' }}
          m={{ md: '0 auto' }}
        >
          <Button
            mode="icon"
            variant="icon"
            mr="space.m"
            width="40px"
            minWidth="0"
            height="40px"
            onClick={prev}
            disabled={swiperIndex === 0}
            _disabled={{ opacity: '1', cursor: 'not-allowed' }}
          >
            <PageArrow fill={swiperIndex === 0 ? '#BFC2C6' : undefined} transform="scaleX(-1)" />
          </Button>
          <Button
            mode="icon"
            variant="icon"
            width="40px"
            minWidth="0"
            height="40px"
            onClick={nexto}
            disabled={swiperIndex === 2}
            _disabled={{ opacity: '1', cursor: 'not-allowed' }}
          >
            <PageArrow fill={swiperIndex === 2 ? '#BFC2C6' : undefined} />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default FeatureSwiper;
