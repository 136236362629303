import React, { useState, useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import ButtonDefault from './button';

const GDPR = () => {
  const [showGDPR, setShowGDPR] = useState(false);

  const AcceptCookies = () => {
    setShowGDPR(false);
    window.localStorage.setItem('alreadyGDPR', 'true');
  };

  useEffect(() => {
    const gdprStatus = window.localStorage.getItem('alreadyGDPR');
    if (gdprStatus !== 'true') {
      setShowGDPR(true);
    }
  }, []);

  return (
    <Flex
      w="89%"
      left="50%"
      borderRadius="8px"
      transform="translateX(-50%)"
      display={showGDPR ? 'flex' : 'none'}
      wrap="wrap"
      alignItems={{ md: 'center' }}
      justify={{ md: 'space-between' }}
      position="fixed"
      bottom={showGDPR ? '20px' : '-100px'}
      transition="0.5s bottom ease-in-out"
      zIndex={3}
      bg="rgba(255, 255, 255, 0.75)"
      boxShadow="0px -1px 0px rgba(0, 0, 0, 0.08)"
      backdropFilter="blur(20px)"
      p="24px 32px"
    >
      <Text
        color="#00335D"
        fontSize="14px"
        lineHeight="20px"
        fontWeight={400}
        mb="20px"
        w={{ md: '70%', lg: '84%' }}
      >
        This website stores data such as cookies to enable essential site functionality, as well as
        marketing, personalization, and analytics. By clicking accept or remaining on this website
        you indicate your consent.
      </Text>
      <ButtonDefault
        width={{ base: '100%', md: '176px' }}
        background="transprant"
        _hover={{ bg: 'transprant' }}
        onClick={AcceptCookies}
        border="1px solid"
        borderColor="icon.text"
        height="40px"
        p="10px"
      >
        <Text as="span" fontWeight={500} fontSize="14px" lineHeight="20px" color="icon.text">
          Accept all cookies
        </Text>
      </ButtonDefault>
    </Flex>
  );
};

export default GDPR;
