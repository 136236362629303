import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import I18n from '../I18n';
import xlargeBannerImg from '../../../static/new_images/aboutBanner/1920px.png';
import largeBannerImg from '../../../static/new_images/aboutBanner/1440px.png';
import mediumBannerImg from '../../../static/new_images/aboutBanner/768px.png';
import baseBannerImg from '../../../static/new_images/aboutBanner/360px.png';
import { AnimationButton } from '../button/index';
import download from '../../utils/download';

const Banner = () => (
  <Flex
    backgroundImage={{
      base: baseBannerImg,
      md: mediumBannerImg,
      lg: largeBannerImg,
      xl: xlargeBannerImg,
    }}
    height={{ base: '800px', md: '1024px', lg: '810px', '2xl': '1080px' }}
    width="100%"
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '142px', md: '248px', lg: '112px' }}
    pb="40px"
    bgRepeat="no-repeat"
    backgroundSize={{ base: 'cover', lg: 'inherit' }}
    backgroundPosition="center 70%"
  >
    <Flex
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      width="100%"
      maxWidth="1200px"
      margin="0 auto"
      color="white"
    >
      <Text
        fontSize={{ base: '44px', md: '72px' }}
        fontWeight="weight.m"
        lineHeight={{ base: '120%', md: '110%' }}
        width={{ base: '100%', md: '417px', lg: '704px' }}
      >
        <I18n id="blocto.about.banner.title" />
      </Text>
      <Text
        fontSize={{ base: '16px', md: '20px' }}
        lineHeight={{ base: '130%', md: '140%' }}
        width={{ base: '90%', md: '50%', lg: '700px', xl: '850px', '2xl': '390px' }}
        mt="space.s"
        mb="space.xl"
      >
        <I18n id="blocto.about.banner.content" />
      </Text>

      <AnimationButton
        variant="filled"
        background="white"
        color="font.primary"
        mr="space.2xs"
        mb={{ base: 'space.s', md: '0' }}
        width="fit-content"
        onClick={() => download('main_feature_block')}
      >
        <I18n id="blocto.index.banner.button1" />
      </AnimationButton>
    </Flex>
  </Flex>
);

export default Banner;
