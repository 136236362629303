import React from 'react';
import { Box, Text, Grid } from '@chakra-ui/react';
import I18n from '../I18n';

const Milestone = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '40px', md: '80px' }}
    pb={{ base: '60px', md: '80px' }}
    bg="border.highlight"
    borderRadius={{ base: '30px 30px 0 0', md: '60px 60px 0 0' }}
    position="relative"
    _after={{
      display: 'block',
      content: '""',
      bg: 'border.highlight',
      width: '100%',
      height: { base: '20px', md: '40px' },
      position: 'absolute',
      bottom: { base: '-20px', md: '-40px' },
      left: '0',
      zIndex: '-1',
    }}
  >
    <Grid
      justifyItems="center"
      w="100%"
      maxW="1200px"
      m="0 auto"
      gap={{ base: '24px', md: '80px' }}
    >
      <Text
        color="font.inverse"
        fontSize={{ base: '24px', md: '49px' }}
        fontWeight="weight.m"
        lineHeight="120%"
      >
        <I18n id="blocto.about.mission.title" />
      </Text>
      <Text
        color="font.inverse"
        fontSize={{ base: '20px', md: '39px' }}
        fontWeight="weight.m"
        lineHeight="120%"
      >
        <I18n id="blocto.about.mission.content" />
      </Text>
    </Grid>
  </Box>
);

export default Milestone;
