import React from 'react';
import { Flex, Box, Text, Image, Tag, Center, AspectRatio, Grid } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import I18n from '../I18n';
import edwin from '../../../static/new_images/edwin.png';
import hsuan from '../../../static/new_images/hsuan.png';
import BgImg from '../../../static/new_images/founders-bg.png';
import BgImgSm from '../../../static/new_images/founders-bg-sm.png';
import CustomLink from '../customLink';
import IconX from '../../../static/new_images/social-item-twitter.svg';
import IconLinkedin from '../../../static/new_images/social-item-linkedin.svg';

const Products = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '40px', md: '120px' }}
    pb={{ base: '80px', lg: '120px' }}
    borderRadius={{ base: '30px', md: '60px' }}
    bg="linear-gradient(180deg, #E2F3FF 14.58%, rgba(252, 252, 252, 0.00) 100%)"
    position="relative"
    backgroundImage={{ base: BgImgSm, md: BgImg }}
    bgSize={{ base: 'contain', md: '100%' }}
    bgPosition="center"
    bgRepeat="no-repeat"
  >
    <Box w="100%" maxW="1200px" m="0 auto">
      <Center width="100%">
        <Text
          color="white"
          fontSize={{ base: '32px', md: '49px' }}
          fontWeight="weight.m"
          lineHeight="120%"
          mb={{ base: 'space.s', lg: 'space.xl' }}
        >
          <I18n id="blocto.about.founders" />
        </Text>
      </Center>
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        mt={{ base: '40px', md: '80px' }}
        gap={{
          base: '14px',
          md: '24px',
        }}
      >
        <ProductCard
          imgSrc={edwin}
          tag={<I18n id="blocto.about.founders.coo" />}
          title={<I18n id="blocto.about.founders.edwin" />}
          twitter="https://twitter.com/edwinsmallyen"
          linkedin="https://www.linkedin.com/in/edwin-wt-yen/"
        />

        <ProductCard
          imgSrc={hsuan}
          tag={<I18n id="blocto.about.founders.ceo" />}
          title={<I18n id="blocto.about.founders.hsuan" />}
          twitter="https://twitter.com/hleetw"
          linkedin="https://www.linkedin.com/in/hleetw/"
        />
      </Grid>
    </Box>
  </Box>
);

export default Products;

const ProductCard = ({ imgSrc, tag, title, twitter, linkedin }) => (
  <Grid
    gridTemplateColumns={{
      base: '1fr',
      md: 'repeat(2, 1fr)',
    }}
    gridTemplateRows={{
      base: 'min-content 1fr',
      md: '1fr',
    }}
    p="24px"
    gap="24px"
    borderRadius="20px"
    background="rgba(255, 255, 255, 0.50)"
    backdropFilter="blur(50px)"
    transition="0.5s background ease-in-out"
    boxShadow="0px 25px 35px 0px rgba(0, 117, 255, 0.10)"
  >
    <AspectRatio
      ratio={1}
      order={{
        base: '1',
        md: '2',
      }}
    >
      <Image src={imgSrc} width="100%" height="100%" fit="cover" borderRadius="12px" />
    </AspectRatio>

    <Grid
      order={{
        base: '2',
        md: '1',
      }}
      width="100%"
      height="100%"
      gridTemplateRows="min-content 1fr min-content"
    >
      <Tag variant="blue" width="fit-content">
        {tag}
      </Tag>
      <Text
        color="icon.text"
        fontSize={{ base: '20px', md: '31px' }}
        fontWeight="weight.m"
        lineHeight="120%"
        my="space.s"
      >
        {title}
      </Text>
      <Flex>
        <CustomLink
          href={twitter}
          isExternal
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Image src={IconX} alt="twitter" mr="space.xs" />
        </CustomLink>
        <CustomLink
          href={linkedin}
          isExternal
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Image src={IconLinkedin} alt="linkedin" />
        </CustomLink>
      </Flex>
    </Grid>
  </Grid>
);

ProductCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
};
