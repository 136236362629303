import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Center,
  Text,
  Grid,
  UnorderedList,
  ListItem,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FormattedDate, useIntl } from 'gatsby-plugin-intl';
import I18n from '../I18n';
import DetailJA360 from '../../../static/new_images/detailCard/ja-360px.png';
import DetailJA768 from '../../../static/new_images/detailCard/ja-768px.png';
import DetailJA1440 from '../../../static/new_images/detailCard/ja-1440px.png';
import DetailEN360 from '../../../static/new_images/detailCard/en-360px.png';
import DetailEN768 from '../../../static/new_images/detailCard/en-768px.png';
import DetailEN1440 from '../../../static/new_images/detailCard/en-1440px.png';

const Years = ({ year, detail }) => {
  const ref = useRef(null);
  const [isOnTop, setIsOnTop] = useState(false);
  const STICKY_TOP = useBreakpointValue({ base: '148px', md: '226px' });

  useEffect(() => {
    const onScroll = () => {
      const distanceToTop = ref.current.getBoundingClientRect().top;
      if (distanceToTop < parseInt(STICKY_TOP, 10)) {
        setIsOnTop(true);
      } else {
        setIsOnTop(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [STICKY_TOP]);

  return (
    <Grid ref={ref} templateColumns="auto 1fr" gap={{ base: '12px', md: '24px' }}>
      <Box position="relative">
        <Box
          fontSize="25px"
          fontWeight="500"
          width={{ base: '75px', md: '80px' }}
          position="sticky"
          top={STICKY_TOP}
          mb="24px"
          color={isOnTop ? '#0075FF' : '#BAE0FF'}
        >
          {year}
        </Box>
      </Box>

      <Grid
        templateColumns="auto 1fr"
        alignItems="center"
        pb="32px"
        px={{ base: '12px', md: '24px' }}
        gap="10px"
      >
        <Box
          position="sticky"
          top={STICKY_TOP}
          gridColumn="1 / 3"
          height="4px"
          borderRadius="4px"
          backgroundColor={isOnTop ? '#0075FF' : '#BAE0FF'}
          mb="14px"
        />
        {detail.map((item) => (
          <Fragment key={item.key}>
            <Center p="8px 16px" borderRadius="30px" border="1px solid #00325A">
              <FormattedDate value={new Date(2021, item.month - 1)} month="short" />
            </Center>
            <UnorderedList paddingInlineStart="22px" m="0 2px">
              {item.key.map((key) => (
                <ListItem key={key} m="0" p="0" lineHeight="140%">
                  <I18n id={key} />
                </ListItem>
              ))}
            </UnorderedList>
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

Years.propTypes = {
  year: PropTypes.string.isRequired,
  // Array of { month: PropTypes.number, content: PropTypes.string}
  detail: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.number,
      key: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
};

const IMAGE_SIZE_MAPPING = {
  en: {
    base: DetailEN360,
    md: DetailEN768,
    lg: DetailEN1440,
  },
  ja: {
    base: DetailJA360,
    md: DetailJA768,
    lg: DetailJA1440,
  },
};

const Features = () => {
  const intl = useIntl();
  const size = useBreakpointValue({ base: 'base', md: 'md', lg: 'lg' });
  const STICKY_TOP = useBreakpointValue({ base: '148px', md: '226px' });

  return (
    <>
      <Box
        height={{ base: '24px', md: '40px' }}
        bg="background.primary"
        borderRadius={{ base: '30px 30px 0 0', md: '60px 60px 0 0' }}
      />
      <Box
        px={{ base: '24px', md: '64px', lg: '120px' }}
        pb="80px"
        bg="background.primary"
        position="relative"
      >
        <Grid
          w="100%"
          maxW="1200px"
          m="0 auto"
          position="relative"
          justifyItems={{ base: 'center', lg: 'left' }}
          gridTemplateColumns={{
            base: '1fr',
            lg: '50% 50%',
          }}
        >
          <Text
            gridColumnStart="1"
            gridColumnEnd={{ base: '2', lg: '3' }}
            textAlign="center"
            width="100%"
            height="100%"
            fontSize={{ base: '24px', md: '49px' }}
            fontWeight="weight.m"
            lineHeight="120%"
            mt={{ base: '-20px', md: '-40px' }}
            pb={{ base: '40px', md: '80px', lg: '88px' }}
            pt={{ base: '100px', md: '120px' }}
            position="sticky"
            top="0px"
            bottom="0"
            background="white"
            zIndex="2"
          >
            <I18n id="blocto.about.detail.title" />
          </Text>
          <Box position="relative" zIndex="1">
            <Image
              src={IMAGE_SIZE_MAPPING[intl.locale][size]}
              width={{ base: '100%', lg: '370px' }}
              position="sticky"
              top={STICKY_TOP}
              mb={{
                base: '64px',
                lg: '0',
              }}
            />
          </Box>

          <Box position="relative" width="100%" zIndex="1">
            <Years
              year="2019"
              detail={[
                { month: 8, key: ['blocto.about.detail.201908'] },
                { month: 11, key: ['blocto.about.detail.201911'] },
              ]}
            />
            <Years
              year="2020"
              detail={[
                { month: 7, key: ['blocto.about.detail.202007'] },
                { month: 8, key: ['blocto.about.detail.202008'] },
              ]}
            />
            <Years
              year="2021"
              detail={[
                { month: 3, key: ['blocto.about.detail.202103'] },
                { month: 9, key: ['blocto.about.detail.202109'] },
                { month: 10, key: ['blocto.about.detail.202110'] },
                { month: 11, key: ['blocto.about.detail.202111'] },
              ]}
            />
            <Years year="2022" detail={[{ month: 10, key: ['blocto.about.detail.202210'] }]} />
            <Years
              year="2023"
              detail={[
                { month: 2, key: ['blocto.about.detail.202302-1', 'blocto.about.detail.202302-2'] },
                { month: 3, key: ['blocto.about.detail.202303'] },
                { month: 9, key: ['blocto.about.detail.202309'] },
                { month: 11, key: ['blocto.about.detail.202311'] },
              ]}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default Features;
