import React from 'react';
import { Box, Image, Flex } from '@chakra-ui/react';
import I18n from '../I18n';
import CtaBottom from '../ctaBottom';
import SubscribeEmail from '../ctaBottom/subscribeEmail';
import gtagEvent from '../../utils/gtag';
import LocationIcon from '../../../static/new_images/location.png';

const CTA_DATA = [
  {
    bg: '#00ADFF',
    title: <I18n id="blocto.cta.integratedWith.title" />,
    content: <I18n id="blocto.cta.integratedWith.content" />,
    buttonText: <I18n id="blocto.cta.integratedWith.button" />,
    onClick: () => {
      gtagEvent('bloctoweb_click_sdkpage', { location: 'integration_block' });
      window.location.href = '/sdk';
    },
  },
  {
    bg: 'boGlobalColor.aqua.500',
    title: <I18n id="blocto.cta.ecosystem.title" />,
    content: <I18n id="blocto.cta.ecosystem.content" />,
    buttonText: <I18n id="blocto.cta.ecosystem.button" />,
    onClick: () => {
      gtagEvent('bloctoweb_click_ecosystem', {
        location: 'integration_block',
      });
      window.location.href = '/web3-ecosystem';
    },
  },
];

const CTA = () => (
  <Box
    px={{ base: '24px', md: '64px', lg: '120px' }}
    pt={{ base: '40px', md: '80px', lg: 0 }}
    pb={{ base: '80px', lg: '160px' }}
  >
    <Box w="100%" maxW="1200px" m="0 auto">
      <Flex
        w="100%"
        mb={{ base: '12px', md: '24px' }}
        mt={{ base: '40px', md: '120px' }}
        p="24px"
        borderRadius="20px"
        bg="#EEF1F5"
      >
        <Image src={LocationIcon} boxSize="120px" />
        <Flex
          direction="column"
          ml={{ base: '12px', md: '24px' }}
          justifyContent="center"
          gap="12px"
        >
          <Box
            fontSize={{ base: '16px', md: '31px' }}
            fontWeight={500}
            color="#00325A"
            lineHeight="110%"
          >
            <I18n id="blocto.cta.location.name" />
          </Box>
          <Box
            fontSize={{ base: '14px', md: '20px' }}
            fontWeight={400}
            color="#777A7D"
            lineHeight="110%"
          >
            <I18n id="blocto.cta.location.address" />
          </Box>
        </Flex>
      </Flex>
      <CtaBottom data={CTA_DATA}>
        <SubscribeEmail />
      </CtaBottom>
    </Box>
  </Box>
);

export default CTA;
