import React from 'react';
import Mission from '../components/about/mission';
import Timeline from '../components/about/timeline';
import Founders from '../components/about/founders';
import GDPR from '../components/gdpr';
import Swiper from '../components/about/swiper';
import Banner from '../components/about/banner';
import Layout from '../components/layout';
import CTA from '../components/about/cta';
import SEO from '../components/seo';
import { AppContext } from '../context/index';

const IndexPage = () => (
  <AppContext.Consumer>
    {() => (
      <Layout>
        <SEO
          title="About Blocto"
          description="Discover, connect, and engage with Web3 on your own terms"
          path="about"
        />
        <Banner />
        <Mission />
        <Timeline />
        <Founders />
        <Swiper />
        <CTA />
        <GDPR />
      </Layout>
    )}
  </AppContext.Consumer>
);

export default IndexPage;
