import { Icon } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

const PageArrow = ({ fill = '#00325A', ...rest }) => (
  <Icon
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="1" y="0.952148" width="40" height="40" rx="20" fill="white" />
    <path
      d="M17.1905 28.5713L25.3077 22.6968C26.6752 21.7072 26.6752 20.0958 25.3077 19.1062L17.3307 13.3332"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="1" y="0.952148" width="40" height="40" rx="20" stroke={fill} strokeWidth="0.5" />
  </Icon>
);

PageArrow.propTypes = {
  fill: PropTypes.string,
};

PageArrow.defaultProps = {
  fill: '#00325A',
};

export default PageArrow;
